import $ from 'jquery';
import 'owl.carousel';

$(document).ready(function () {
  $('.testi1').owlCarousel({
    loop: true,
    margin: 60,
    nav: true,
    navText: ["<", ">"],
    dots: false,
    // autoplay: true,
    // autoplayTimeout: 13000,
    autoWidth: true,
    center: true
  });

  $('.logos').show().owlCarousel({
    loop: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2300,
    autoWidth: true,
    center: true,
    lazyLoad: true,
    margin: 120,
  });

  function injectYTApi() {
    var s = document.createElement('script');
    s.setAttribute("type", "text/javascript");
    s.setAttribute("src", "https://www.youtube.com/player_api");
    s.setAttribute("id", "youtube-api-script");
    document.getElementsByTagName('head')[0].appendChild(s);
  }
  window.$sl = {};
  window.$sl.injectYoutube = function (modalId, code, target) {
    var iframe = document.createElement("iframe");
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("width", "100%");
    iframe.setAttribute("height", "500px");
    iframe.setAttribute("id", modalId + "-viedo-player")
    iframe.setAttribute("allowfullscreen", "");
    iframe.setAttribute("allow", "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture");
    iframe.setAttribute("src", "https://www.youtube.com/embed/" + code + "?enablejsapi=1&version=3&modestbranding=1&showinfo=0&rel=0");
    if (!$('#' + modalId).find('iframe').length) {
      target.appendChild(iframe);
      if (!$("#yotube-api-script").length > 0) {
        injectYTApi();
      }
    }

    $('#' + modalId).on('shown.bs.modal', function () {
      setTimeout(function () {
        $(this).find('iframe')[0].contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
        var ytPlayer = new YT.Player(modalId + "-viedo-player", {
          videoId: code,
          events: {
            onStateChange: (event) => onPlayerStateChange(event, ytPlayer)
          }
        });
      }.bind($(this)), 200)
    });

    $('#' + modalId).on('hide.bs.modal', function () {
      $(this).find('iframe')[0].contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
    });
  }

  function onPlayerStateChange(event, player) {
    if (typeof window.dataLayer === 'undefined') return
    switch (event.data) {
      case YT.PlayerState.PLAYING:
        window.YTtimeout = setTimeout(function (player) {
          if ((player.getCurrentTime() >= player.getDuration() / 2)) {
            window.dataLayer.push({ event: 'video-reached-middle' });
            YTtimeout = undefined;
          };
        }, player.getDuration() * 1000 / 2)
        if (cleanTime(player) == 0) {
          window.dataLayer.push({ event: 'video-started' });
        }
        break;
      case YT.PlayerState.ENDED:
        window.dataLayer.push({ event: 'video-ended' });
        break;
    };
  };

  function cleanTime(player) {
    return Math.round(player.getCurrentTime())
  };

  $(function () {
    $('.card-sellers').hide();
    $('#card-lukasz-k').show();
    $('#inlineFormCustomSelect').change(function (event) {
      $('.card-sellers').hide();
      $('#card-' + event.target.value).show();
    });
  });

  function setupPostAnimation() {
    var posts = $('.timeline-date');
    $(window).on('scroll resize', function () {

      var currScroll = $(window).scrollTop() > $(document).scrollTop() ? $(window).scrollTop() : $(document).scrollTop(),
        windowHeight = $(window).height(), // Needs to be here because window can resize
        treshhold = (currScroll + (windowHeight) / 2) - 21;

      posts.each(function () {

        var post = $(this),
          postScroll = post.offset().top
        if (postScroll > treshhold) {
          post.removeClass('active');
        } else {
          post.addClass('active');
        }

      });

    });
  }
  setupPostAnimation();
  addStickyMenuAnimation();
});


function addStickyMenuAnimation() {
  $(window).scroll(function () {
    var y = $(this).scrollTop();
    if (y >= 750) {
      $('.topbar').addClass('resized');
    } else {
      $('.topbar').removeClass('resized');
    }
  })
}
